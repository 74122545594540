import clsx from 'clsx';

type Props = {
  className?: string;
};

export const Footer = ({ className }: Props) => {
  return (
    <footer className={clsx('Footer', className)}>
      <p className="Footer-copyright">&#169; conocer</p>
    </footer>
  );
};
