import { clsx } from 'clsx';
import { Footer } from 'app/components/common/Footer';
import logoConojuku from '@monorepo_2022/ui/src/assets/images/logo-conojuku.svg';
import backgroundImage from '@monorepo_2022/ui/src/assets/images/bg-theme.webp';

type Props = {
  children: React.ReactNode;
  className?: string | undefined;
};

export const MinimalLayout = ({ children, className }: Props) => (
  <div
    className={clsx('MinimalLayout', className)}
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className="MinimalLayout-main">
      <div className="MinimalLayout-body">
        <h1 className="MinimalLayout-title">
          <img
            src={logoConojuku}
            alt="コノ塾"
            width={134}
            height={40}
            className="MinimalLayout-logo"
          />
          Coach App
        </h1>
        {children}
      </div>
    </div>
    <Footer />
  </div>
);
